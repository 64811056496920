<template>
  <section>
    <section class="sections" id="headerSection">
      <div class="spacerMedium"></div>
      <picture>
        <!-- <source
          srcset="@/assets/images/screenshot1-dark.png"
          media="(prefers-color-scheme: dark)"
        /> -->
        <img
          id="screenshot1"
          class="screenshots"
          src="@/assets/images/1.png"
          alt="Screenshot"
        />
      </picture>
      <div class="spacerMedium"></div>
      <h1>Track Your Emotions</h1>
      <div class="spacerSmall"></div>
      <p>
        It helps you identify your emotions throughout the day and supports you
        when you’d like to shift to a different emotion.
      </p>
      <div class="spacerMedium"></div>

      <div class="spacerLarge"></div>
    </section>
    <section class="sections" id="addSection">
      <picture>
        <!-- <source
          srcset="@/assets/images/screenshot2-dark.png"
          media="(prefers-color-scheme: dark)"
        /> -->
        <img
          id="screenshot2"
          class="screenshots"
          src="@/assets/images/2.png"
          alt="Screenshot"
        />
      </picture>
      <div class="spacerMedium"></div>
      <h2>Relaxing Games</h2>
      <div class="spacerSmall"></div>
      <p>
        Start exploring an extensive collection of relaxing Slime games designed
        to challenge and soothe different negative emotions.
      </p>
      <div class="spacerLarge"></div>
    </section>
    <section class="sections" id="remindersSection">
      <div class="spacerLarge"></div>
      <h2>Funny Tests</h2>
      <div class="spacerSmall"></div>
      <p>
        It is your digital life coach, helping you make sense of yourself across
        life’s twists and turns.
      </p>
      <div class="spacerMedium"></div>
      <picture>
        <!-- <source
          srcset="@/assets/images/screenshot3-dark.png"
          media="(prefers-color-scheme: dark)"
        /> -->
        <img
          id="screenshot3"
          class="screenshots"
          src="@/assets/images/3.png"
          alt="Screenshot"
        />
      </picture>
    </section>
    <section class="sections" id="featuresSection">
      <div class="spacerLarge"></div>
      <h2>Become a Better You</h2>
      <div class="spacerSmall"></div>
      <p>
        It is a beautiful and modern app that captures your emotions and moods.
        It goes along with you throughout your life, and gives you a deeper
        understanding of yourself. Ultimately, this will allow you to improve
        your mental health and help you become a better you.
      </p>
      <div class="spacerMedium"></div>
      <picture>
        <img
          id="screenshot4"
          class="screenshots"
          src="@/assets/images/4.png"
          alt="Screenshot"
        />
      </picture>
    </section>
    <section class="sections" id="stanSection">
      <div class="spacerLarge"></div>
      <h2>Track Your Emotions Today</h2>
      <div class="spacerSmall"></div>
      <p>💦🌱</p>
      <div class="spacerMedium"></div>

      <div class="spacerLarge"></div>
    </section>
  </section>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.home-section {
  height: 100%;
  /* overflow: hidden; */
}

#comp-kx685r6w {
  visibility: hidden !important;
}
</style>
