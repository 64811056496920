import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPage from "../views/PrivacyTerms/PrivacyPage.vue";
import TermsPage from "../views/PrivacyTerms/TermsPage.vue";
import Contact from "../views/Contact/Contact.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/about-privacy",
    name: "privacy",
    component: PrivacyPage,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/about-terms",
    name: "terms",
    component: TermsPage,
  },
  {
    path: "/read-terms",
    name: "read-terms",
    component: Home,
  },
];

const routerInstance = () => {
  // write name into path:
  const name = process.env.VUE_APP_NAME;
  const router = new VueRouter({
    mode: "history",
    base: window.__POWERED_BY_QIANKUN__
      ? `/micro/${name}/`
      : process.env.BASE_URL,
    routes,
  });

  return router;
};

export default routerInstance;
