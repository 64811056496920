<template>
  <section>
    <h2 class="page-title">TERMS OF USE</h2>
    <TermsContent></TermsContent>
  </section>
</template>
<script>
import TermsContent from "@/components/PrivacyTerms/TermsContent";

export default {
  components: {
    TermsContent
  }
};
</script>
<style scoped>
.page-title {
  font-size: 32px;
  text-align: center;
  padding: 64px 0 32px;
}
</style>
