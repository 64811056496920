<template>
  <div id="container">
    <form id="form" name="contact_form" action="javascript:;">
      <h3>Contact Me</h3>

      <label>
        <span id="nameLabel">Name:</span>
        <input
          type="text"
          id="name"
          name="name"
          v-model="name"
          autocomplete="given-name"
          maxlength="50"
          autofocus=""
        />
        <p>{{ nameError }}</p>
      </label>

      <label>
        <span id="emailLabel">Email:</span>
        <input
          type="email"
          id="email"
          name="email"
          v-model="email"
          autocomplete="email"
          maxlength="200"
        />
        <p>{{ emailError }}</p>
      </label>

      <label>
        <span id="subjectLabel">Subject:</span>
        <input
          type="text"
          id="subject"
          v-model="subject"
          name="subject"
          maxlength="140"
        />
        <p>{{ subjectError }}</p>
      </label>

      <label>
        <span id="messageLabel">Message:</span>
        <textarea
          id="message"
          name="message"
          spellcheck="true"
          maxlength="2000"
          v-model="message"
        ></textarea>
        <p>{{ messageError }}</p>
      </label>

      <input id="submit" value="Submit" @click="clickSubmit" />
    </form>
    <Toast v-model="toastValue" :error="errorToast"></Toast>
  </div>
</template>
<script>
import { sendEmail } from "@/api/question/index.js";
import DefaultParams from "@/utils/defalut_params.js";
import CachSendEmail from "@/utils/cach_send_email.js";
import Toast from "@/components/Toast/Toast";
export default {
  components: {
    Toast
  },
  data() {
    return {
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      subject: "",
      subjectError: "",
      message: "",
      messageError: "",
      toastValue: "",

      errorToast: false
    };
  },
  methods: {
    clickSubmit() {
      const canSendEmail = CachSendEmail.sendEmailEnable();
      this.emailError =
        this.nameError =
        this.subjectError =
        this.messageError =
          "";
      if (!canSendEmail) {
        // 一个小时内超过两次，禁止发送
        this.errorToast = true;
        this.toastValue = "You operation are too frequent. Take a break!";
        return;
      }

      if (!this.name) {
        this.nameError = "Please Enter Name.";
        return;
      }
      let re = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
      if (!this.email || !re.test(this.email)) {
        this.emailError = "Please enter a valid email address.";
        return;
      }
      if (!this.subject) {
        this.subjectError = "Please Enter Subject.";
        return;
      }
      if (!this.message) {
        this.messageError = "Please Enter Message.";
        return;
      }

      const nativeParams = { ...DefaultParams.getAppInfo() };
      const access_token = nativeParams.access_token;
      delete nativeParams["access_token"];
      const body = {
        ...nativeParams,
        feedback: {
          // question_type: this.selectValue.index,
          question: this.subject,
          name: this.name,
          email: this.email,
          suggestion: this.message
        }
      };
      sendEmail(body, access_token)
        .then(() => {
          CachSendEmail.storageSendParams();
          this.errorToast = false;
          this.toastValue = "Thanks for submitting.";
          this.clearFrom();
        })
        .catch(err => {
          console.log("clickSendEmail err", err);
          this.errorToast = true;
          this.toastValue = "Something went wrong.";
        });
    },
    clearFrom() {
      this.name = this.email = this.subject = this.message = "";
    }
  }
};
</script>
<style>
#wrapper {
  max-width: 1300px;
}
@font-face {
  font-family: Journal;
  src: url("~@/assets/font/journal.woff");
}
h3 {
  font-family: Journal, sans-serif;
  font-size: 44px;
  font-weight: normal;
}
#container {
  text-align: center;
  margin: 6em 0 10em 0;
}
#form {
  text-align: left;
  width: 400px;
  margin: 0 auto 0 auto;
}
#form h3 {
  text-align: center;
  margin: 0px 0px 20px 0px;
}
#form span {
  color: #b0b0b0;
}
#form label p {
  margin-bottom: 22px;
  font-size: 12px;
  color: red;
}
#name,
#email,
#subject {
  width: 100%;
  font-size: 16px;
}
#message {
  width: 400px;
  height: 200px;
  resize: vertical;
  font-size: 16px;
}
#submit {
  float: right;
  position: relative;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 16px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #6fb9f2;
  color: #6fb9f2;
  background: transparent;
  width: 100px !important;
}
#submit:hover {
  border-color: #3eabff;
  color: #3eabff;
}
#submit:active {
  position: relative;
  top: 1px;
}
#arrow {
  margin-bottom: 5px;
}
footer {
  position: fixed;
  bottom: 0;
}

@media (prefers-color-scheme: dark) {
  #submit {
    border-color: lightgray;
    color: lightgray;
  }
  #submit:hover {
    border-color: white;
    color: white;
  }
}

@media screen and (max-height: 800px) {
  footer {
    position: static;
  }
}
@media screen and (max-device-height: 570px) {
  footer {
    position: static;
  }
}

/* Phone Portrait */
@media screen and (max-device-width: 640px) and (orientation: portrait) {
  #form {
    width: 90%;
  }
  #name,
  #email,
  #subject {
    width: 100%;
  }
  #message {
    width: 100%;
  }
}
</style>
