<template>
  <footer>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/about-privacy">Privacy Policy</router-link></li>
      <li><router-link to="/about-terms">Terms Of Use</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>
    <div class="spacerMedium"></div>
  </footer>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  },
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>
<style scoped>
.contact-list {
  margin-right: 16px;
}
</style>
