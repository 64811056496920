<template>
  <section id="contact">
    <div class="container">
      <div class="row content">
        <div class="col-lg-5">
          <Contact></Contact>
        </div>
        <div class="col-lg-7">
          <div class="img-box">
            <!-- <img
              class="right-icon"
              src="@/assets/images/contact/illustration.jpg"
              alt=""
            /> -->
          </div>
        </div>
      </div>
      <!-- <div class="privacy-terms">
        <router-link to="/privacy-policy" class="link">Privacy</router-link>
        <router-link to="/terms-of-service" class="link">Terms</router-link>
      </div> -->
    </div>
  </section>
</template>
<script>
import Contact from "@/components/Contact";

export default {
  components: {
    Contact
  }
};
</script>

<style lang="scss">
#contact {
  max-width: 1320px;
  width: 90%;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;

  .input-filed {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .label-tip {
      font-size: 15px;
      line-height: 26px;
      font-weight: 400;
    }
  }
  input,
  textarea {
    box-shadow: inset 0 0 0 1px #d5dce3;
    border: none;
    color: #3e4854;
    outline: none;
    font-size: 15px;
    line-height: 26px;
    padding: 12px;
    width: 100%;
    background: #fff;
    &:focus {
      box-shadow: inset 0 0 0 1px rgb(96, 196, 245);
    }
  }
  .error {
    color: rgb(245, 43, 43);
    font-weight: 400;
  }
  .img-box {
    width: 80%;
    margin-left: 20%;
    .right-icon {
      width: 100%;
      margin: 0 auto;
    }
  }
  .privacy-terms {
    color: #3e4854;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    .link:hover {
      color: rgb(96, 196, 245);
    }
  }
  @media (max-width: 1199px) {
    .content {
      display: flex;
      flex-direction: column-reverse;
      .img-box {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  @media (min-width: 1200px) {
    .privacy-terms {
      position: absolute;
      right: 10%;
      bottom: 35%;
    }
  }
}
</style>
