<template>
  <div
    v-if="value"
    class="toast-alert alert"
    :class="error ? 'alert-danger' : 'alert-success'"
    role="alert"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    error: {
      default: false
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          setTimeout(() => {
            this.$emit("input", "");
          }, 2000);
        }
      },
      immediate: true
    }
  }
};
</script>

<style>
.toast-alert {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.alert {
  padding: 0.75rem;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}
</style>
